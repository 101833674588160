<template>
<!-- /section -->
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">

		<!--/.row -->
		<div class="grid grid-view projects-masonry shop mb-13">
			<div class="row gx-md-8 gy-10 gy-md-13 isotope">
				<div class="project item col-md-6 col-xl-4">
					<figure class="rounded mb-6">
						<img src="https://media.berlcoin.fr/sandboxland.png"
								 srcset="https://api.fraction.re/sandbox310/assets/img/photos/sh1@2x.jpg 2x"
								 alt="" />
						<a class="item-like"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Add to wishlist"><i class="uil uil-heart"></i></a>
						<a class="item-view"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Quick view"><i class="uil uil-eye"></i></a>
						<a href="#"
							 class="item-cart"><i class="uil uil-shopping-bag"></i> Add to Cart</a>
						<span class="avatar bg-pink text-white w-10 h-10 position-absolute text-uppercase fs-13"
									style="top: 1rem; left: 1rem;"><span>Sale!</span></span>
					</figure>
					<div class="post-header">
						<div class="d-flex flex-row align-items-center justify-content-between mb-2">
							<div class="post-category text-ash mb-0">MetaVerse</div>
							<span class="   ">25% / an</span>
						</div>
						<h2 class="post-title h3 fs-22"><a href="./shop-product.html"
								 class="link-dark">Terrain dans le metaverse</a></h2>
						<p class="price"> <span class="amount">10000 tokens</span> </p>
					</div>
					<!-- /.post-header -->
				</div>
				<!-- /.item -->
				<div class="project item col-md-6 col-xl-4">
					<figure class="rounded mb-6">
						<img src="https://media.berlcoin.fr/roubaix.jpg"
								 srcset="https://api.fraction.re/sandbox310/assets/img/photos/sh2@2x.jpg 2x"
								 alt="" />
						<a class="item-like"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Add to wishlist"><i class="uil uil-heart"></i></a>
						<a class="item-view"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Quick view"><i class="uil uil-eye"></i></a>
						<a href="#"
							 class="item-cart"><i class="uil uil-shopping-bag"></i> Add to Cart</a>
					</figure>
					<div class="post-header">
						<div class="d-flex flex-row align-items-center justify-content-between mb-2">
							<div class="post-category text-ash mb-0">France</div>
							<span class="ratings four"></span>
						</div>
						<h2 class="post-title h3 fs-22"><a href="./shop-product.html"
								 class="link-dark">Maison Roubaix</a></h2>
						<p class="price"><span class="amount">3 520 tokens</span></p>
					</div>
					<!-- /.post-header -->
				</div>
				<!-- /.item -->
				<div class="project item col-md-6 col-xl-4">
					<figure class="rounded mb-6">
						<img src="https://media.berlcoin.fr/douai.jpg"
								 srcset="https://api.fraction.re/sandbox310/assets/img/photos/sh3@2x.jpg 2x"
								 alt="" />
						<a class="item-like"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Add to wishlist"><i class="uil uil-heart"></i></a>
						<a class="item-view"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Quick view"><i class="uil uil-eye"></i></a>
						<a href="#"
							 class="item-cart"><i class="uil uil-shopping-bag"></i> Add to Cart</a>
						<span class="avatar bg-aqua text-white w-10 h-10 position-absolute text-uppercase fs-13"
									style="top: 1rem; left: 1rem;"><span>New!</span></span>
					</figure>
					<div class="post-header">
						<div class="d-flex flex-row align-items-center justify-content-between mb-2">
							<div class="post-category text-ash mb-0">France</div>
						</div>
						<h2 class="post-title h3 fs-22"><a href="./shop-product.html"
								 class="link-dark">Maison Douai</a></h2>
						<p class="price"><span class="amount">2 970 Tokens</span></p>
					</div>
					<!-- /.post-header -->
				</div>
				<!-- /.item -->
				<div class="project item col-md-6 col-xl-4">
					<figure class="rounded mb-6">
						<img src="https://media.berlcoin.fr/lille.jpg"
								 srcset="https://api.fraction.re/sandbox310/assets/img/photos/sh4@2x.jpg 2x"
								 alt="" />
						<a class="item-like"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Add to wishlist"><i class="uil uil-heart"></i></a>
						<a class="item-view"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Quick view"><i class="uil uil-eye"></i></a>
						<a href="#"
							 class="item-cart"><i class="uil uil-shopping-bag"></i> Add to Cart</a>
					</figure>
					<div class="post-header">
						<div class="d-flex flex-row align-items-center justify-content-between mb-2">
							<div class="post-category text-ash mb-0">Shoes</div>
							<span class="ratings three"></span>
						</div>
						<h2 class="post-title h3 fs-22"><a href="./shop-product.html"
								 class="link-dark">Maison Lille</a></h2>
						<p class="price"><span class="amount">4 500 tokens</span></p>
					</div>
					<!-- /.post-header -->
				</div>
				<!-- /.item -->
				<div class="project item col-md-6 col-xl-4">
					<figure class="rounded mb-6">
						<img src="https://media.berlcoin.fr/property/c17-1/1.jpg"
								 srcset="https://api.fraction.re/sandbox310/assets/img/photos/sh5@2x.jpg 2x"
								 alt="" />
						<a class="item-like"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Add to wishlist"><i class="uil uil-heart"></i></a>
						<a class="item-view"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Quick view"><i class="uil uil-eye"></i></a>
						<a href="#"
							 class="item-cart"><i class="uil uil-shopping-bag"></i> Add to Cart</a>
					</figure>
					<div class="post-header">
						<div class="d-flex flex-row align-items-center justify-content-between mb-2">
							<div class="post-category text-ash mb-0">Electronics</div>
							<span class="ratings one"></span>
						</div>
						<h2 class="post-title h3 fs-22"><a href="./shop-product.html"
								 class="link-dark">Polaroid Camera</a></h2>
						<p class="price"><span class="amount">$55.00</span></p>
					</div>
					<!-- /.post-header -->
				</div>
				<!-- /.item -->
				<div class="project item col-md-6 col-xl-4">
					<figure class="rounded mb-6">
						<img src="https://media.berlcoin.fr/property/c17-2/1.jpg"
								 srcset="https://api.fraction.re/sandbox310/assets/img/photos/sh6@2x.jpg 2x"
								 alt="" />
						<a class="item-like"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Add to wishlist"><i class="uil uil-heart"></i></a>
						<a class="item-view"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Quick view"><i class="uil uil-eye"></i></a>
						<a href="#"
							 class="item-cart"><i class="uil uil-shopping-bag"></i> Add to Cart</a>
					</figure>
					<div class="post-header">
						<div class="d-flex flex-row align-items-center justify-content-between mb-2">
							<div class="post-category text-ash mb-0">Cosmetics</div>
							<span class="ratings two"></span>
						</div>
						<h2 class="post-title h3 fs-22"><a href="./shop-product.html"
								 class="link-dark">Curology Cleanser</a></h2>
						<p class="price"><span class="amount">$55.00</span></p>
					</div>
					<!-- /.post-header -->
				</div>
				<!-- /.item -->
				<div class="project item col-md-6 col-xl-4">
					<figure class="rounded mb-6">
						<img src="https://media.berlcoin.fr/property/g35-1/1.jpg"
								 srcset="https://api.fraction.re/sandbox310/assets/img/photos/sh7@2x.jpg 2x"
								 alt="" />
						<a class="item-like"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Add to wishlist"><i class="uil uil-heart"></i></a>
						<a class="item-view"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Quick view"><i class="uil uil-eye"></i></a>
						<a href="#"
							 class="item-cart"><i class="uil uil-shopping-bag"></i> Add to Cart</a>
					</figure>
					<div class="post-header">
						<div class="d-flex flex-row align-items-center justify-content-between mb-2">
							<div class="post-category text-ash mb-0">Home & Kitchen</div>
							<span class="ratings four"></span>
						</div>
						<h2 class="post-title h3 fs-22"><a href="./shop-product.html"
								 class="link-dark">Q&Q Wall Clock</a></h2>
						<p class="price"><span class="amount">$55.00</span></p>
					</div>
					<!-- /.post-header -->
				</div>
				<!-- /.item -->
				<div class="project item col-md-6 col-xl-4">
					<figure class="rounded mb-6">
						<img src="https://api.fraction.re/sandbox310/assets/img/photos/sh8.jpg"
								 srcset="https://api.fraction.re/sandbox310/assets/img/photos/sh8@2x.jpg 2x"
								 alt="" />
						<a class="item-like"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Add to wishlist"><i class="uil uil-heart"></i></a>
						<a class="item-view"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Quick view"><i class="uil uil-eye"></i></a>
						<a href="#"
							 class="item-cart"><i class="uil uil-shopping-bag"></i> Add to Cart</a>
					</figure>
					<div class="post-header">
						<div class="d-flex flex-row align-items-center justify-content-between mb-2">
							<div class="post-category text-ash mb-0">Electronics</div>
						</div>
						<h2 class="post-title h3 fs-22"><a href="./shop-product.html"
								 class="link-dark">Earphones</a></h2>
						<p class="price"><span class="amount">$55.00</span></p>
					</div>
					<!-- /.post-header -->
				</div>
				<!-- /.item -->
				<div class="project item col-md-6 col-xl-4">
					<figure class="rounded mb-6">
						<img src="https://api.fraction.re/sandbox310/assets/img/photos/sh9.jpg"
								 srcset="https://api.fraction.re/sandbox310/assets/img/photos/sh9@2x.jpg 2x"
								 alt="" />
						<a class="item-like"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Add to wishlist"><i class="uil uil-heart"></i></a>
						<a class="item-view"
							 href="#"
							 data-bs-toggle="white-tooltip"
							 title="Quick view"><i class="uil uil-eye"></i></a>
						<a href="#"
							 class="item-cart"><i class="uil uil-shopping-bag"></i> Add to Cart</a>
					</figure>
					<div class="post-header">
						<div class="d-flex flex-row align-items-center justify-content-between mb-2">
							<div class="post-category text-ash mb-0">Accessories</div>
							<span class="ratings five"></span>
						</div>
						<h2 class="post-title h3 fs-22"><a href="./shop-product.html"
								 class="link-dark">Apple Watch Milano Loop</a></h2>
						<p class="price"><span class="amount">$55.00</span></p>
					</div>
					<!-- /.post-header -->
				</div>
				<!-- /.item -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.grid -->
		<nav class="d-flex justify-content-center"
				 aria-label="pagination">
			<ul class="pagination">
				<li class="page-item disabled">
					<a class="page-link"
						 href="#"
						 aria-label="Previous">
						<span aria-hidden="true"><i class="uil uil-arrow-left"></i></span>
					</a>
				</li>
				<li class="page-item active"><a class="page-link"
						 href="#">1</a></li>
				<li class="page-item"><a class="page-link"
						 href="#">2</a></li>
				<li class="page-item"><a class="page-link"
						 href="#">3</a></li>
				<li class="page-item">
					<a class="page-link"
						 href="#"
						 aria-label="Next">
						<span aria-hidden="true"><i class="uil uil-arrow-right"></i></span>
					</a>
				</li>
			</ul>
			<!-- /.pagination -->
		</nav>
		<!-- /nav -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
const eurocurrency = new Intl.NumberFormat('fr-FR', {
	style: 'currency',
	currency: 'EUR',
	minimumFractionDigits: 2
});


export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
